<template>
  <!-- TOP Nav Bar -->
  <header id="main-header">
    <div class="main-header">
      <b-container fluid>
        <b-row>
          <b-col sm="12">
            <nav class="navbar navbar-expand-lg navbar-light p-0">

              <a href="javascript:void(0)" class="navbar-toggler c-toggler collapsed" @click="openSidebar">
                <div class="navbar-toggler-icon" data-toggle="collapse">
                  <span class="navbar-menu-icon navbar-menu-icon--top"></span>
                  <span class="navbar-menu-icon navbar-menu-icon--middle"></span>
                  <span class="navbar-menu-icon navbar-menu-icon--bottom"></span>
                </div>
              </a>


              <router-link v-if="sidebar == false && !isDesktopSize" class="navbar-brand" to="/">
                <img class="img-fluid logo" :src="require('../../../assets/myreligion_logo.svg')" alt="Entertainia"
                  size="202" height="10" width="10px" />
              </router-link>



              <!--show on mobile  -->
              <div v-show="sidebar == false && isDesktopSize == false">

                <div class="navbar-right mx-4">
                  <div v-if="$i18n.locale == 'en'"> Points : {{ userPoints }} </div>
                  <div v-if="$i18n.locale == 'ku'">خاڵەکانم : {{ userPoints }}</div>
                  <div v-if="$i18n.locale == 'ar'">نقاط : {{ userPoints }}</div>
                </div>

                <div class="dropdown" v-show="sidebar == false && isDesktopSize == false">
                  <button class="dropbtn" @click="showLang = !showLang">
                    <span style="font-size: 18px;">
                      <span v-if="$i18n.locale == 'en'">English</span>
                      <span v-if="$i18n.locale == 'ku' || $i18n.locale == null"> کوردی </span>
                      <span v-if="$i18n.locale == 'ar'"> عربی </span>
                      <i class="fas fa-caret-down"></i>
                    </span>
                  </button>
                  <div class="dropdown-content" v-show="sidebar == false && showLang">
                    <span @click="changeLang('en')">English</span>
                    <span @click="changeLang('ku')">کوردی</span>
                    <span @click="changeLang('ar')">عربی</span>
                  </div>
                </div>

              </div>
              <!--  -->





              <div class="d-flex align-items-center" v-if="isDesktopSize">
                <div class="navbar-right mx-4" v-show="sidebar == false">
                  <div v-if="$i18n.locale == 'en'"> Points : {{ userPoints }} </div>
                  <div v-if="$i18n.locale == 'ku'">خاڵەکانم : {{ userPoints }}</div>
                  <div v-if="$i18n.locale == 'ar'">نقاط : {{ userPoints }}</div>
                </div>

                <div class="dropdown" v-show="sidebar == false">
                  <button class="dropbtn" @click="showLang = !showLang">
                    <span style="font-size: 18px;">
                      <span v-if="$i18n.locale == 'en'">English</span>
                      <span v-if="$i18n.locale == 'ku' || $i18n.locale == null"> کوردی </span>
                      <span v-if="$i18n.locale == 'ar'"> عربی </span>
                      <i class="fas fa-caret-down"></i>
                    </span>
                  </button>
                  <div class="dropdown-content" v-show="sidebar == false && showLang">
                    <span @click="changeLang('en')">English</span>
                    <span @click="changeLang('ku')">کوردی</span>
                    <span @click="changeLang('ar')">عربی</span>
                  </div>
                </div>

              </div>







              <b-collapse id="navbarSupportedContent" is-nav :visible="sidebar">
                <div class="menu-main-menu-container" v-if="categories.length">
                  <ul id="top-menu" class="navbar-nav ml-auto" dir="rtl">
                    <li class="menu-item">
                      <a @click="gotoPage('/')" v-if="$i18n.locale == 'en'"> Home </a>
                      <a @click="gotoPage('/')" v-if="$i18n.locale == 'ku'"> سەرەکی </a>
                      <a @click="gotoPage('/')" v-if="$i18n.locale == 'ar'"> الرئيسية</a>
                    </li>
                    <li v-for="(item, index) in categories" :key="index" class="menu-item">
                      <a @click="gotoPage('/category/' + item.id)">{{
                        item.name?.[$i18n.locale]
                      }}</a>
                    </li>
                    <li class="menu-item mx-3">
                      <a @click="gotoPage('/myVideos/')" v-if="$i18n.locale == 'en'"> My Content </a>
                      <a @click="gotoPage('/myVideos/')" v-if="$i18n.locale == 'ku'"> لیستەکەم </a>
                      <a @click="gotoPage('/myVideos/')" v-if="$i18n.locale == 'ar'"> محتوياتي </a>
                    </li>

                    <li class="menu-item">
                      <a @click="gotoPage('/ramadan')" v-if="$i18n.locale == 'en'">Ramadan</a>
                      <a @click="gotoPage('/ramadan')" v-if="$i18n.locale == 'ku'">ڕەمەزان</a>
                      <a @click="gotoPage('/ramadan')" v-if="$i18n.locale == 'ar'">رمضان</a>
                    </li>




                  </ul>
                </div>
              </b-collapse>








              <router-link v-if="sidebar == false && isDesktopSize" class="navbar-brand" to="/">
                <img class="img-fluid logo" :src="require('../../../assets/myreligion_logo.svg')" alt="Entertainia"
                  size="202" height="10" width="10px" />
              </router-link>





            </nav>
          </b-col>
        </b-row>
      </b-container>
    </div>
  </header>
  <!-- TOP Nav Bar END -->
</template>
<script>
import { core, APPLOGONAME } from "../../../config/pluginInit"
import ApiService from "../../../services/api"

export default {
  name: "FrontendNav",
  props: {
    logo: { type: String, default: require("../../../assets/myreligion_logo.svg") },
    items: { type: Array },
    userprofile: { type: String },
  },
  data() {
    return {
      appName: APPLOGONAME,
      sidebar: false,
      categories: [],
      isSelectLanguage: true,
      showLang: false,
      isDesktopSize: false,
    }
  },
  mounted() {
    this.$i18n.locale = localStorage.getItem("lang") ?? "ku"
    core.index()
    this.getCategories()
    this.checkScreenSize();
    // Listen for window resize events to update isDesktopSize
    window.addEventListener('resize', this.checkScreenSize);
  },
  methods: {
    checkScreenSize() {
      // Update isDesktopSize based on screen width
      this.isDesktopSize = window.innerWidth >= 768; // Adjust the breakpoint as needed
    },
    changeLang(lang) {
      this.showLang = false
      this.$i18n.locale = lang
      localStorage.setItem("lang", lang)
      ApiService.changeLAng()
      this.$http.defaults.headers.common["lang"] = localStorage.getItem("lang")
    },
    gotoPage(link) {
      document.getElementsByTagName("body")[0].classList.remove("nav-open")
      this.sidebar = false
      this.$router.push(link)
    },
    openSidebar() {
      if (this.sidebar === false) {
        document.getElementsByTagName("body")[0].classList.add("nav-open")
        this.sidebar = true
      } else {
        document.getElementsByTagName("body")[0].classList.remove("nav-open")
        this.sidebar = false
      }
    },
    getCategories() {
      ApiService.getCategories().then((response) => {
        this.categories = response.data.data
      })
    }
  },
  computed: {
    userPoints() {
      return this.$store.state.points
    }
  },
  watch: {
    "$i18n.locale": function () {
      this.showLang = false
    },
  }
}
</script>
<style>
#top-menu {
  text-align-last: right !important;
}

.dropbtn {
  border: none;
  background-color: transparent;
  color: #f0eeee;

  @media (min-width: 1024px) {
    margin-top: 0px;
  }
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f8f8f8;
  min-width: 120px;
  box-shadow: 0px 8px 16px 0px rgba(145, 42, 42, 0.2);
  z-index: 1;
  right: -13px;
}

.dropdown-content span {
  color: rgb(20, 20, 20);
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  cursor: pointer;
}

.dropdown-content span:hover {
  background-color: v-bind(primaryColor);
  color: #ffffff;
  transition: 0.3s;
  background-color: #486069;
}

.dropdown:hover .dropdown-content {
  display: block;
}

.dropdown:hover .dropbtn {
  transition: 0.5s;
  background-color: v-bind(primaryColor);
}

@media (max-width: 768px) {
  .navbar-right {
    font-size: 13px;
  }

  #top-menu {
    text-align-last: left !important;
  }
}
</style>